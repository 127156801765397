import React from "react"
import { Link } from "gatsby"

import HeaderResources from "../../../components/header_resources"

export default function TheMostImportantThing() {
  const heroTitle = "The Most Important Thing – Howard Marks (HM)"

  return (
    <HeaderResources heroTitle={heroTitle} layout="1Column">
      <div class="w-100 mb-5 sm:mmb-5">
        <Link class="back--home" to="/resources/book-summaries">
          <i class="fas fa-chevron-left"></i> <span>Back to Book Summaries</span>
        </Link>
      </div>
      <div class="innerpage--content innerpage--content-sm blog--details m-0 p-0">
        <h2 class="h2 h2-sm">{heroTitle}</h2>
        <p>
          I read the illuminated edition of this book which is annotated by Christopher Davis, Joel
          Grenblatt, Paul Johnson and Seth Klarman. The insights shared by these investors were also
          interesting. I have set out below a summary, key takeaways / learning outcomes, some
          ramblings and unanswered questions.
        </p>
        <p>
          As a general comment, Howard Marks (HM) not only explains the concept of second-level
          thinking but also gives pointers of how to develop it. This is quite an important and
          helpful element for better investing and one of my main takeaways from this book. The fact
          that there as so many elements to investing that qualify as “the most important thing”
          just validates the fact that successful investing is a successful confluence of a
          multitude of aspects.
        </p>

        <h3>Chapter 1 - Second Level Thinking</h3>
        <p>
          HM mentions that development of perceptive thinking – which consists of superior insight,
          intuition, sense of value and awareness of psychology – is necessary to achieve
          above-average results. Given that second level thinking is deep, complex and convoluted –
          it is the antithesis of simple. As a second level thinker one needs to take into account,
          amongst others:
        </p>
        <ul class="list-icon list-icon-arrow list-icon-colored">
          <li>
            What is the range of likely future outcomes and which outcome do I think will occur?
          </li>
          <li>What is the probability of being right?</li>
          <li>What does the consensus think and how does my expectation differ from consensus?</li>
          <li>
            Is the consensus psychology that’s incorporated in the price too bullish or bearish?
          </li>
          <li>
            What will happen to the asset’s price if the consensus turns out to be right and what if
            I am right?
          </li>
        </ul>
        <p>
          I think that developing second level thinking requires a lot of hard work and, to an
          extent, experience. Otherwise, most investors are condemned to be first level thinkers and
          just invest on simplistic and superficial insights – a la consensus. One not only needs to
          have different thinking but a better one. It is also necessary to be right – merely being
          unconventional or different will not suffice.{" "}
        </p>
        <h3>Chapter 2 - Understanding market efficiency (and its limitations)</h3>
        <p>
          HM gives an important perspective of what “efficiency” actually means when he says that
          efficient used in market efficiency would mean “speedy, quick to incorporate information”
          in the price and not necessarily “right” (i.e. efficiency should not be confused as to
          mean that the prices reflected are right or have correctly incorporated the information
          available). Asset prices may not be right because at times flawed incentives are at work
          which explains irrational, destructive and counterintuitive behaviors.
        </p>
        <p>
          Most people are driven by greed, fear, envy and other emotions which may render
          objectivity impossible and open the door for mistakes and inefficiencies. These
          inefficiencies then generate “mispricings” – which is the essential raw material that is
          used by better investors to generate superior returns. It is quite important to have a
          handle on your emotions and develop strength in your stomach and when mispricings occur,
          one should act on them. HM mentions a few additional questions one should ask – I think
          they again help in developing second level thinking
        </p>
        <ul class="list-icon list-icon-arrow list-icon-colored">
          <li>
            Why should a bargain exist despite the presence of 1000s of analysts and investors who
            are willing to buy anything cheap?
          </li>
          <li>
            If the returns appear generous in proportion to the risk, am I overlooking some hidden
            risk?
          </li>
          <li>
            Why would the seller of the asset be willing to part with it at a price that will give
            me an excessive return? Do I really know more than the seller?
          </li>
        </ul>
        <h3>Chapter 3 - Value</h3>
        <p>
          HM believes that value / growth investing – both require dealing with the future – require
          a good handle on being able to compute intrinsic value and that too correctly (to the
          extent possible). Having said that, even if you have bought something below its intrinsic
          value, your judgment may not be proven correct quickly and may even appear to be wrong for
          a while and it is OK to look wrong for extended periods.{" "}
        </p>
        <p>
          HM says that an accurate estimation of fair / intrinsic value helps in eliminating the
          emotional side of investing – which is one of the most difficult sides / elements of
          investing to keep in check.
        </p>
        <h3>Chapter 4 - The relationship between price and value</h3>
        <p>
          I agree when HM says that no asset is so good that it cannot become a bad investment if
          bought at too high a price and there are few assets so bad that they cannot be a good
          investment when bought cheap enough.
        </p>
        <h3>Chapter 5 - Understanding Risk</h3>
        <p>
          Begins with a wonderful quote from Elroy Dimson that “Risk means more things can happen
          than will happen”. Risk is inescapable since investing consists of dealing with the future
          and future is uncertain. One needs to be able to judge how risky an investment is and when
          one can live with the risk and then whether the return on investment justifies the risk
          being taken. Riskier investments – where outcome is less certain – need to offer higher
          returns but those higher returns also need to materialize.
        </p>
        <p>
          Paul Johnson says that the risk of permanent loss of capital is the only risk one should
          worry about.
        </p>
        <h3>Chapter 6 - Recognizing Risk</h3>
        <p>
          Has so many pearls of wisdom, another one – “Risk means uncertainty about which outcome
          will occur and about the possibility of loss when the unfavourable ones do”. In this
          regard, being aware of and understanding the relationship between price and value is an
          essential component of dealing with risk.
        </p>
        <p>
          Risk primarily arises from the behavior of market participants and their perception of
          there being no risk or their acceptance of a high risk without a promised high return for
          acceptance of such risk. The risk-is-gone myth is one of the most dangerous sources of
          risk, and a major contributor to any bubble. HM also mentions that prediction of the
          future is much less important than having an awareness of the present. Having your mental
          faculties functioning in an orderly and an alert manner in the present can help in
          avoiding disasters.
        </p>
        <h3>Chapter 7 - Controlling Risk</h3>
        <p>
          Intelligently bearing risk for profit is essential. Removing or minimizing the element of
          a disastrous outcome is as important as generating high return. Risk control is typically
          not rewarded in the usual parlance because risk is covert and invisible and the
          possibility of loss is not observable unless the loss occurs.
        </p>
        <p>
          An analogy can be drawn from the fact that the score line of a football match always shows
          the goals scored and not the ones saved. In investing, preventing goals is equally
          important.{" "}
        </p>
        <p>
          Benchmark return with lower than benchmark return is also a superior outcome. Risk control
          is the best route to loss avoidance - even if it is difficult to predict and prepare for
          black swan events. It is only in bad years that the value of risk control becomes evident.
        </p>
        <h3>Chapter 8 - Being attentive to cycles</h3>
        <p>
          <strong>
            Just about everything is cyclical. HM explains in a very lucid manner by setting out two
            rules:
          </strong>
        </p>
        <ul class="list-icon list-icon-arrow list-icon-colored">
          <li>Rule number one: most things will prove to be cyclical</li>
          <li>
            Rule number two: some of the greatest opportunities for gain and loss come when other
            people forget rule number one.
          </li>
        </ul>
        <p>
          Another point to remember in this regard is that human emotions and inconsistencies create
          market cycles and these cycles are self-correcting. It is akin to success carrying the
          seeds of failure and failure carrying the seeds of success. Credit cycle is the most
          important amongst all and produces the largest fluctuations.
        </p>
        <p>
          In respect of cycles, “This time its different” – these words should strike fear as they
          signify absence of risk aversion and presence of complacency.{" "}
        </p>

        <h3>Chapter 9 - Awareness of the pendulum</h3>
        <p>
          HM says that the mood swings of the markets resemble the movement of a pendulum. Rather
          than spending time in the middle it is almost always swinging towards or away from the
          extremes of the arc. He goes on to say that the oscillation is the most dependable feature
          of the investment world and investor psychology lets it oscillate.
        </p>
        <p>
          On one end of the pendulum investors become too risk-tolerant and prices embody more risk
          than return and on the other end investors become too risk-averse that prices embody more
          return than risk. Understanding the behavior of the pendulum is extremely important even
          if it is difficult to know when it will swing the other way.{" "}
        </p>
        <p>
          A key takeaway is that patience is extremely important because the pendulum may remain in
          one extreme for a long time. Best time to buy would be when the tide has gone out – you
          will find that many were swimming naked and will give you bargains. One should be ready to
          pounce during such opportunities.
        </p>

        <h3 class="mt-5">Chapter 10 - Combating negative influences</h3>
        <h4>
          One needs to fight against and be opportunistic when others fail to control the following
          negative influences:
        </h4>
        <ol>
          <li>
            Greed – so strong that it overcomes, amongst others, common sense, risk aversion,
            prudence, logic and painful past lessons
          </li>
          <li>
            Fear – it is the overdone concern that prevents investors from taking constructive
            action when they should. Therefore, be greedy when others are fearful and fearful when
            others are greedy – are the only times when one of these negative influences can work in
            your favour
          </li>
          <li>
            Brevity of financial memory – inability to remember past cycles and yours and others
            past mistakes
          </li>
          <li>
            Tendency to conform to the herd – pressure to go with the crowd leading drop in your own
            independence of thought and skepticism
          </li>
          <li>Envy – most harmful aspects of human nature- comparing yourself to others</li>
          <li>Ego</li>
          <li>
            Capitulation – self –doubt which leads to selling an underpriced stock or buying an
            overpriced stock{" "}
          </li>
        </ol>

        <p>
          HM says that one needs to have the courage to resist them and the following will often
          help:
        </p>
        <ul class="list-icon list-icon-arrow list-icon-colored">
          <li>a strong held sense of intrinsic value</li>
          <li>being aware of how much you don’t know</li>
          <li>insistence on acting as you should when price diverges from value</li>
          <li>being conversant with past cycles, market excesses are punished not rewarded</li>
          <li>remembering that when things seem “too good to be true”, they usually are</li>
          <li>willingness to look wrong when markets goes from misvalued to more misvalued</li>
          <li>like minded friends and colleagues to gain support and provide them support</li>
        </ul>

        <h3>Chapter 11 - Contrarianism</h3>
        <p>
          Difficult to teach because the logic of crowd error is clear and most mathematical and so
          one tends to follow the crowd and so contrarianism comes from experience. One must do
          things not just because they are the opposite of what the crowd is doing but because you
          know why the crowd is wrong. With contrarianism you should also be ready to look wrong for
          a period of time because your conviction and contrarianism may take longer to play out.
          Skepticism and pessimism are not synonymous – though thought to be so. Skepticism calls
          for pessimism when optimism is excessive. But it also calls for optimism when pessimism is
          excessive.
        </p>

        <h3>Chapter 12 - Finding bargains</h3>
        <p>
          The first step in the process of finding bargains is to ensure that the shopping list
          satisfies some absolute standards. Then it is to select the best investments from the list
          by the process of “relative selection” because its not what you buy but what you pay for
          it. HM explains that since the efficient market process involves people (who are mostly
          analytical and objective), bargains arise because of their irrationality or incomplete
          understanding. Bargains get created when investors either fail to consider an asset fairly
          or fail to look beneath the surface to understand it thoroughly or fail to overcome some
          non-value based tradition, bias or stricture. Greater the revulsion or stigma for the
          asset, the better the bargain. For the existence of bargains –perception has to be worse
          than reality.
        </p>
        <p>
          HM gives some pointers for finding them – not fully understood, questionable on the face
          of it, controversial, deemed inappropriate for “respectable” portfolios, unappreciated or
          unpopular, recently the subject of disinvestment or having a record of poor returns in the
          past.{" "}
        </p>

        <h3>Chapter 13 - Patient opportunism</h3>
        <p>
          Begins with an excellent tip “You’ll do better if you wait for investments to come to you
          rather than go chasing after them”. An unwise way to make investments would be to act
          without recognizing the market’s status or acting with indifference to its status or
          believing we can somehow change its status. One way to avoid investing in a loser is to
          understand and ascertain whether we are in a low return environment or high return
          environment.{" "}
        </p>
        <p>
          One should try and avoid being a forced seller (who have to sell regardless of price) and
          take risk when others are fleeing from it, not when they are competing with you to do so.
          To benefit from patient opportunism one should have staunch reliance on value, little or
          NO use of leverage, long term capital and strong stomach.{" "}
        </p>

        <h3>Chapter 14 - Knowing what you don’t know</h3>
        <p>
          It is very very important for you to know what you don’t know. I will go as far to say
          that anything about which you have simplistic or superficial understanding is in the
          bucket of don’t know. However, one should try to know what is knowable – i.e. developing
          superior insights about the knowable.
        </p>
        <p>
          HM says that predicting the future looking in the rearview mirror i.e. the past won’t be
          of much help. While the future is typically correlated to the recent past, unpredictable /
          black swan events may occur. One needs to be humble and acknowledge that a lot of stuff is
          unknowable or correctly predictable. Here again, awareness of the present and a sense of
          where we stand also helps. Some of the biggest losses typically occur when we have
          overconfidence about our predictive ability and we underestimate the range of
          possibilities – particularly the ones where there will be negative outcomes.
        </p>
        <p>
          Like Buffett says, identify your circle of competence or identify your smart spots and
          remain within that circle or around those spots.
        </p>
        <p>
          To quote Mark Twain – “it ain’t what you don’t know that gets you into trouble, its what
          you know for sure that just ain’t so”.
        </p>

        <h3>Chapter 15 - A sense of where we stand</h3>
        <p>
          Given that the most predictable thing is that market cycles are inevitable, we can and
          should act by taking into account where we stand in those cycles. A torrent of capital
          flooding the market and predominance of buyers relative to sellers is where one should be
          most cautious. HM gives a wonderful checklist of conducting a temperature check of the
          market so that one can calibrate one’s actions accordingly.{" "}
        </p>

        <h3>Chapter 16 - Appreciating the role of luck</h3>
        <p>
          Relying heavily on Nassim Nicholas Taleb for this chapter, HM says that in order to
          prevent being fooled by randomness, we need to learn to identify the true source of our
          success. One should be able to recognize whether the outcome was a result of luck or
          skill. Randomness has the ability to bring about any outcome in the short run and a lucky
          idiot can be confused for a skilled investor. A skilled decision is one that a logical,
          intelligent and informed person would have made under the circumstances as they appeared
          at the time, before the outcome was known.
        </p>
        <p>
          As with risk of loss, many things that will bear on the correctness of a decision cannot
          be known or quantified in advance. Just because a decision resulted in profits doesn’t not
          necessarily imply that it was the most intelligible one. In order to increase the
          probability of the outcome being influenced by skill than pure luck, one should spend time
          to find value among the knowable – industries, companies and securities, have a good sense
          of intrinsic value, ensure survival when negative outcomes play out and try not to follow
          the herd and being correct in doing so.
        </p>

        <h3>Chapter 17 - Investing defensively</h3>
        <p>
          Well this one requires a solid check on testosterone because investing defensively
          requires a large part of decision making to be employed towards avoiding losers – not a
          bad thing at all from my perspective.
        </p>
        <p>
          Though HM emphasizes that there is no right choice between offensive and defensive
          investing, he concludes that he prefers the latter – something I wouldn’t disagree with.
          In this form of investing, building a margin of safety / margin of error assumes huge
          importance. Like Buffett says don’t drive a truck carrying 9,000 tonnes over a bridge that
          has capacity to hold 10,000 tonnes. This approach helps in keeping the result tolerable
          when undesirable outcomes materialize. Joel Greenblatt also weighs in here and says that
          if one were to minimize the chance of loss in an investment, most of the alternatives are
          good. So perhaps even when following concentrated investing – which HM says is offense
          approach to investing – having a margin of safety and considering the possibility of
          negative outcomes while taking positions can provide a happy balance. Concludes by saying
          that it is best to “Invest Scared!” i.e. being circumspect is a good approach.
        </p>

        <h3>Chapter 18 - Avoiding pitfalls</h3>
        <p>
          As Buffett says “an investor needs to do very few things right as long as he/ she avoids
          big mistakes.” Avoiding losses can be done more often and more dependably and with
          consequences when it fails that are more tolerable. HM suggests one should avoid the big
          analytical and psychological errors. The big psychological error is failing to recognize
          market cycles and manias and moving in the opposite direction. One should remember that
          things that aren’t supposed to happen do happen and short-run outcomes can diverge from
          the long-run probabilities and occurrences can cluster.
        </p>
        <p>
          The success of your investment actions shouldn’t be highly dependent on normal outcomes
          prevailing; instead, one must allow for outliers. Unable to do so is a failure of
          imagination i.e. not anticipating the possible extremeness of future events and failing to
          understand the knock-on consequences of such events.
        </p>
        <p>
          HM says that in order to avoid the pitfalls one must be on the look-out for them and
          shouldn’t disarm our skepticism at any point of time. Being alert to what is going around
          with regard to supply / demand balance for investable funds and the eagerness to spend
          them is one of the ways to avoid falling in the pit. Further, when there is nothing
          particularly clever to do, the pitfall lies in insisting on being clever – inability to
          embrace inaction.
        </p>

        <h3>Chapter 19 - Adding value</h3>
        <p>
          The performance of investors who are able to generate performance that is unrelated to
          movement of the market through superior investment skills is called Alpha and the addition
          of Alpha to one’s portfolio leads to superior results. It is only skill that can be
          counted on to add more in propitious environments than it costs in hostile ones. For
          instance, aggressive investors with skill do well in bull markets but don’t give it all
          back in bear markets and defensive investors with skill lose relatively little in bear
          markets but participate reasonably in bull markets. Asymmetry of returns should be every
          investor’s goal and can be produced consistently only through skill.
        </p>

        <h3>Chapter 20 - Reasonable expectations</h3>
        <p>
          It is the unreal expectations that brings doomsday at your doorstep. Return expectations
          must be explicit and reasonable in their absolute terms and relative to the risk entailed.
          HM says that even skill cannot be counted on to produce high returns in a low- return
          environment. Similarly, it is not realistic to expect to be able to buy at the bottom.
          Perfection in investing is generally unobtainable and the best one can hope for is to make
          a lot of good investment decisions and exclude most of the bad ones – not easy to do
          though. Another important snippet is that one should understand that “cheap” is far from
          synonymous with “not going to fall further”.
        </p>
        <p>
          Happens to me many times, I buy a stock considering it has become cheap and then it falls
          further. If someone wants to short a stock they should sell it and ask me to go buy it
          from the market because after me buying it will fall further.{" "}
        </p>
        <p>
          HM says aspiring for and expecting “good enough returns” is sufficient. Realistic
          expectations help in keeping emotions realistic and in check – which is essential.
        </p>

        <h3>Chapter 21 - Pulling it all together</h3>

        <p>
          Comment - this chapter is what I would call the “Gita ka saar”. HM has summarized the
          various tenets of superior investing in this chapter and, in my view, should be read at
          least once annually.
        </p>
      </div>
    </HeaderResources>
  )
}
